@import 'styles/typography';

.wrapper {
  position: relative;
  margin-bottom: 8rem;
  margin-top: 1.125rem;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .profileName {
    @include h2;

    font-weight: var(--font-weight-bold);

    .profileNameLabel {
      text-transform: uppercase;
    }
  }

  .switchProfileBtn {
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 50px;
    font-weight: var(--font-weight-normal);
  }

  a:first-of-type {
    .switchProfileBtn {
      margin: 0 0.75rem 0 1rem;
    }
  }

  .profileCriteria {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

.navArea {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  margin-bottom: 1.5rem;

  .nav {
    text-transform: uppercase;
    background-color: var(--bs-gray-400);
    padding: 1px 20px;
    border-radius: 9999px;
    white-space: nowrap;

    &:global(.active) {
      background-color: var(--bs-orange);
      color: var(--bs-white);
    }
  }

  .end {
    display: flex;
    justify-content: space-between;
  }

  .navContainer {
    display: flex;
    align-items: center;
    flex: 1;

    &:first-child {
      &::after {
        content: ' ';
        background-color: var(--bs-gray-400);
        height: 2px;
        flex: 1;
        display: inline-flex;
        min-width: 1.5rem;
      }
    }
  }
}

.noResults {
  .message {
    margin-bottom: 0.5rem;
    font-weight: var(--font-weight-bold);
  }

  .actions {
    display: flex;
    gap: 1rem;
  }
}
