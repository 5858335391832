@import 'styles/variables';
@import 'styles/typography';

.navbar {
  --hover-bg: rgb(255 255 255 / 10%);
  --navbar-height: 44px;
  --underline-offset: 3px;

  .menuItem {
    @include h3;

    display: block;
    border-top: 1px solid var(--bs-gray);

    &:nth-child(4n + 1) {
      border-color: var(--bs-gray);
    }

    &:nth-child(4n + 2) {
      border-color: var(--bs-cyan);
    }

    &:nth-child(4n + 3) {
      border-color: var(--bs-red);
    }

    &:nth-child(4n + 4) {
      border-color: var(--bs-purple);
    }
  }

  /* stylelint-disable no-descending-specificity */
  :global {
    .active {
      text-decoration: underline;
      text-underline-offset: var(--underline-offset);

      span {
        text-decoration: underline;
        text-underline-offset: var(--underline-offset);
      }
    }

    .parent-active {
      .dropdown-toggle {
        text-decoration: underline;
        text-underline-offset: var(--underline-offset);
      }
    }

    .nav-link {
      @include h3;

      color: var(--bs-white);
      text-transform: uppercase;
      min-height: var(--navbar-height);
      display: flex;
      align-items: center;

      &.active {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        color: var(--bs-navbar-hover-color);
        background-color: var(--hover-bg);
      }

      &.social-icon {
        padding-left: 5px;
        padding-right: 5px;

        svg {
          font-size: 20px;
        }
      }
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .nav-item.dropdown {
      &:hover,
      &.show {
        .nav-link {
          color: var(--bs-navbar-hover-color);
          background-color: var(--hover-bg);
        }

        .dropdown-menu {
          display: block;
        }
      }
    }

    .dropdown-menu {
      background-color: var(--bs-primary);
      padding: 0;
      transform: translateY(-3px);

      .dropdown-item {
        color: var(--bs-white);
        text-align: right;

        &:hover,
        &:focus {
          background: var(--hover-bg);
        }
      }
    }

    .navbar-toggler-icon {
      filter: brightness(100);
    }

    @media (max-width: 1000px) {
      .me-auto.navbar-nav.navbar-nav-scroll {
        max-height: unset !important;
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}
