@import 'styles/typography';

.accordion {
  width: 100%;
}

.summary {
  display: flex;
  width: 100%;
  background-color: var(--bs-white);
}

.details {
  margin: 22px 19px;
  padding: 25px 23px 32px;
}
