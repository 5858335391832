@import 'styles/typography';

.menuWrapper {
  background: var(--bs-gray-200);
  color: var(--bs-primary);
  min-width: 310px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

  :global {
    .react-select__menu {
      position: relative;
      box-shadow: none;
    }

    .react-select__option {
      font-size: 13px;
    }
  }

  .ctaSection {
    border-top: 1px solid var(--bs-primary);
    padding: 10px;
    text-align: center;

    p {
      margin: 0;
    }

    .ctaBtn {
      margin-top: 0.75rem;

      @include h3;
    }
  }
}
