.fi {
  background-size: cover;
  outline: 1px solid var(--bs-gray-300);
  outline-offset: -1px;
}

.fi-vt {
  background-size: cover;
  outline: 1px solid var(--bs-green-300);
  outline-offset: -1px;
  background-color: green;
}

.fi-rd {
  background-size: cover;
  outline: 1px solid var(--bs-red-300);
  outline-offset: -1px;
  background-color: red;
}

.fi-yl {
  background-size: cover;
  outline: 1px solid var(--bs-yello-300);
  outline-offset: -1px;
  background-color: yellow;
}
