@import 'styles/variables';

.card {
  padding: 1.5rem;
  position: sticky;
  top: 1rem;
}

.title {
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  text-align: center;
  margin-bottom: 3rem;
}
