.actionBtn {
  text-align: end;
}

.collapseBtn,
.expandBtn {
  background-color: transparent;
  text-transform: lowercase;
  border: none;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}
