@import 'styles/typography';

.summary {
  background-color: transparent;
  padding: 9px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  color: var(--bs-primary);
  text-align: left;
  border-top: 1.5px solid var(--bs-primary);
  width: 100%;
  height: 100%;

  &.noBorder {
    border: none;
  }

  .indicator {
    margin-left: 10px;
  }

  svg {
    fill: currentcolor;
  }
}

.summaryWrapper {
  padding: 0 13px;
  flex: 0 0 50%;
  background-color: var(--bs-white);
  border: none;

  &:hover,
  &:focus {
    background-color: var(--bs-gray-300);
  }

  &.expanded {
    background-color: var(--bs-primary);

    .summary {
      color: var(--bs-white);
    }

    // &:hover,
    // &:focus {
    //   background-color: var(--bs-cyan);
    // }
  }
}
