.summaryRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0.875rem 0;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  position: relative;

  .checkmark {
    margin-right: 1rem;
  }

  .statusIndicator {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.rootAccordion {
  .icon {
    svg {
      width: 60px;
      height: 60px;
    }
  }

  .sortingIcon {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 18%;
    z-index: 10;
  }

  .rootHeader {
    position: sticky;
    top: 0;
    z-index: 9999;
  }
}

/* stylelint-disable */
.accordionSummary {
  --border-style: 1.5px solid rgba(var(--bs-primary-rgb), 0.25);

  .groupHeader {
    position: sticky;
    top: 66px;
    z-index: 1;

    .container {
      padding: 1.25rem 0 1.25rem 1rem;
      margin: 0 8px;
      border-bottom: var(--border-style);
      width: 100%;

      &.collapsed {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    :global {
      .accordion-button {
        background-color: var(--bs-gray-200);

        &:hover {
          background-color: var(--bs-gray-400);
        }

        &:focus {
          background-color: var(--bs-primary);
          color: var(--bs-white);
        }

        // &:not(.collapsed) {
        //   background-color: #d4dbde;
        // }
      }
    }

    &.active {
      :global {
        .accordion-button {
          .progress {
            border-color: var(--bs-white);
          }

          .progress-bar {
            background-color: var(--bs-white);
          }

          .measureName {
            font-weight: var(--font-weight-bold);
            color: var(--bs-orange);
          }
        }
      }
    }
  }

  .link {
    display: block;

    &:hover,
    &:focus {
      background-color: var(--bs-gray-400);
    }

    .container {
      margin: 0 8px;
    }

    .children {
      padding-left: 1rem;
      border-bottom: var(--border-style);
    }

    &:global(.active .measureName) {
      font-weight: var(--font-weight-bold);
      color: var(--bs-orange);
    }

    :global {
      .measureName {
        padding-left: 1rem;
      }
    }
  }
}
/* stylelint-enable */
