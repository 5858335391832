@import 'styles/variables';

.widgetArea {
  --fallback-color: #f5edf4;

  background-color: var(--fallback-color);
  min-height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .background {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 1;

    .title {
      position: relative;
      display: flex;
      align-items: center;

      .decoration {
        position: absolute;
        left: -35px;
      }

      .titleText {
        position: relative;
        z-index: 2;
        text-transform: uppercase;
        font-weight: var(--font-weight-bold);
        margin: 0;
      }
    }
  }
}
