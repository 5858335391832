.header {
  background: var(--bs-white);
  border: 1px solid var(--bs-orange);
  padding: 8px 8px 4px;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 152px;
  z-index: 1;

  .count {
    color: var(--bs-orange);
  }
}
