@import 'styles/typography';

.filterBtn {
  border-radius: 0;
  border-width: 1px;

  .selectedContent {
    @include h1;

    line-height: 1;
    font-weight: var(--font-weight-normal);
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
      @include h2;
    }
  }

  &.flipped {
    .selectedContent {
      flex-direction: row-reverse;
    }
  }
}
