@import 'styles/typography';

.table {
  display: table;
  width: 100%;
  height: 100%;

  th,
  td {
    text-align: center;
  }

  th {
    &:first-child {
      width: 45%;
      text-align: start;
    }

    &:nth-child(2),
    &:last-child {
      width: 40px;

      div {
        height: 100%;
      }

      .navigateBtn {
        width: 40px;
        height: 100%;
        font-size: 30px;
        padding: 0;
      }
    }
  }

  thead {
    background-color: var(--bs-orange);
    color: var(--bs-white);
    text-transform: uppercase;
    border-bottom: 1px solid var(--bs-white);
    position: sticky;
    top: 53px;
    z-index: 1;

    .columnHeader {
      padding: 6px 0;
      font-weight: var(--font-weight-normal);

      .flag {
        width: 35px;
        height: 26.25px;
        margin-bottom: 10px;
      }
    }
  }

  .expandButton {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 9px 0 9px 5px;
    text-align: start;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .icon {
      flex-shrink: 0;
    }

    &:hover,
    &:focus {
      background-color: rgb(0 0 0 / 5%);
    }
  }

  .rowLevel2 {
    .expandButton {
      padding: 7px 0 7px 68px;
      flex-shrink: 0;
    }

    .icon {
      width: 70px;
      text-align: center;

      svg {
        width: 38px;
      }
    }
  }

  td {
    .measureNumber {
      @include h3;

      color: var(--bs-red);
      font-weight: var(--font-weight-bold);

      &.positiveNumber {
        color: var(--bs-green);
      }

      &.rowLevel2 {
        color: var(--bs-primary);
        font-weight: var(--font-weight-medium);
      }
    }

    .sectionTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 0 10px;
    }

    .summary {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .image {
        flex-shrink: 0;
        width: 58px;
        height: 58px;
        margin-right: 5px;

        svg {
          width: 100%;
        }
      }
    }
  }

  .rowLevel1 {
    .expandButton {
      &.expanded {
        background-color: var(--bs-primary);
        color: var(--bs-white);

        svg {
          fill: var(--bs-white);
        }
      }

      .icon {
        width: 70px;
        text-align: center;

        svg {
          width: 57px;
        }
      }
    }
  }

  tr {
    &.rowLevel0 {
      background-color: var(--bs-orange);
      color: var(--bs-white);
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);

      & + .rowLevel1 {
        border-top: none;
      }
    }

    &.rowLevel1 {
      background-color: var(--bs-white);
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      border-top: 1px solid var(--bs-primary);

      & + .rowLevel2 {
        border-top: none;
      }
    }

    &.rowLevel2 {
      background-color: var(--bs-gray-200);
      border-top: 1px solid var(--bs-primary);
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
    }

    &.rowLevel3 {
      background-color: var(--bs-gray-200);
    }
  }
}
