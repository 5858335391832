@import 'styles/variables';
@import 'styles/mixins';

.collapseBtn,
.expandBtn {
  background-color: transparent;
  text-transform: lowercase;
  border: none;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

.compareLayoutActionContainer {
  text-align: end;
  margin-bottom: -5px;
  min-height: 28px;
}

.actions,
.body {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .col {
    width: calc(50% - 0.5rem);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--bs-primary);

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .toggleBtn {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    margin-left: 1rem;

    --bs-btn-border-radius: 0.625rem;
    --bs-btn-padding-y: 0.1rem;
  }

  .title {
    text-transform: uppercase;
    width: 250px;
  }
}

.contentWrapper {
  width: 100%;
  padding: 10px;

  .body {
    max-height: 470px;

    .col {
      overflow: auto;

      @include custom-scrollbar;

      .resetPadding {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
