.details {
  .list {
    background-color: var(--bs-white);
  }

  .title {
    line-height: 2;
    font-weight: var(--font-weight-bold);
  }

  .content {
    white-space: pre-wrap;
    padding-bottom: 0.5rem;
  }

  .title,
  .content {
    --padding-space: 25px;

    padding-left: var(--padding-space);
    padding-right: var(--padding-space);
  }

  .indicatorDetail {
    padding: 15px 0 5px;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--bs-primary);
    }
  }

  .complianceCheck {
    display: flex;
    justify-content: space-between;
    margin: 28px 0 8px;

    .legend {
      font-weight: var(--font-weight-medium);
    }

    label {
      font-weight: var(--font-weight-semibold);
    }

    :global {
      .form-check {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .action {
    margin-top: 39px;
    display: flex;
    justify-content: flex-end;
  }

  .table {
    overflow: auto;
    max-width: 100%;

    &.selfContained {
      max-height: 75vh;

      th {
        &.productSpecIndColumn {
          min-width: 240px;
        }
      }
    }
  }
}
