.clearBtn {
  position: absolute;
  right: 0.5rem;
  font-size: 0.8rem;
  transform: none;
  filter: grayscale(1);
}

.searchInput {
  align-items: center;
  display: flex;
  position: relative;

  &.error {
    .input {
      border-color: var(--bs-danger);
    }
  }

  &:not(.fullBorder) {
    :global {
      .form-control {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        background: var(--bs-white);
        padding-left: 0;
        padding-right: 0;
      }
    }

    .input {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.display {
  .container {
    display: inline-flex;
    align-items: center;
    width: 100%;
    min-height: 35px;
    padding: 0 3px;
    transform: translateX(-5px);
    box-sizing: border-box;
    max-width: 100%;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: background 0.2s ease 0s;

    :global {
      .editable-content::after {
        content: '';
        display: inline-block;
        visibility: hidden;
        height: 19px;
        width: 17px;
        background-image: url('../../assets/icons/edit.svg');
        background-size: contain;
        margin-left: 8px;
        transform: translateY(3px);
      }

      .is-updating {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: var(--bs-gray-200);
    }

    &:hover,
    &:focus {
      :global {
        .editable-content::after {
          visibility: visible;
        }
      }
    }
  }

  button {
    display: block;
    margin: 0;
    padding: 0;
    appearance: none;
    background: transparent;
    border: 0 none;
    line-height: 1;
    outline: 0;

    &:focus + .container {
      border: 2px solid var(--bs-gray-500);
    }
  }
}

.form {
  position: relative;

  .actions {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 5px;

    button {
      box-shadow: var(--shadow);
      padding: 0;
      height: 30px;
      width: 30px;
      font-size: 1.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
