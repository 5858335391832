@import 'styles/typography';

.header {
  :global {
    .btn-close:not(.clearInput) {
      color: var(--bs-primary);
      top: 20px;
      right: 20px;
      position: absolute;
    }
  }
}

.description {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.legend {
  list-style: none;
  padding: 0;
  transform: translateX(-17px);
  margin-top: 10px;
  margin-bottom: 0;

  @include body1;

  .legendItem {
    display: flex;
    align-items: center;

    &::before {
      left: 0;
      content: '';
      display: inline-flex;
      width: 50px;
      height: 25px;
      background-color: var(--bs-primary);
      margin-right: 1rem;
    }

    &.ntlc {
      display: flex;
      align-items: center;

      &::before {
        background-color: var(--bs-orange);
      }

      .ntlcDetails {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 0.5rem;
      }
    }
  }
}

.titleSection {
  width: 100%;

  .title {
    text-transform: uppercase;
    color: var(--bs-orange);
  }

  .searchInput {
    align-items: center;
    display: flex;
    position: relative;
    margin-right: 1rem;
  }

  .clearBtn {
    position: absolute;
    right: 0.5rem;
    font-size: 0.8rem;
    transform: none;
    filter: grayscale(1);
  }
}
