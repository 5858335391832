.summary {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .image {
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    margin-right: 20px;

    svg {
      width: 100%;
    }
  }
}

.sorting {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 0.75rem;
}
