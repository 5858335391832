@import 'styles/typography';
@import 'styles/variables';

.measureGroup {
  padding-bottom: 10rem;
}

.loading {
  @include h3;

  font-weight: var(--font-weight-medium);
  display: flex;
  justify-content: center;
}

.widgetArea {
  --fallback-color: #ebf8fd;

  background-color: var(--fallback-color);
  min-height: 300px;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 48px 0 40px;

  .background {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
  }

  .card {
    --spacing: 14px;

    margin-bottom: 100px;
    padding: var(--spacing);
    position: relative;

    .sourceInfo {
      position: absolute;
      bottom: calc(var(--spacing) / 2);
      right: var(--spacing);
      margin-top: 14px;
      min-height: 51px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .list {
        @include body3;

        display: flex;
        margin: 0;

        dt {
          font-weight: normal;
        }

        dd:not(:last-of-type) {
          margin-inline-end: 1.6rem;
        }
      }
    }
  }

  :global {
    .sub-container {
      z-index: 1;
    }
  }
}
