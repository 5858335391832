@import './typography';

.react-select__menu-portal .react-select__menu {
  min-width: 310px;
}

.select-wrapper {
  position: relative;

  &--error {
    padding-bottom: 1.3125rem;
  }

  &__subscript {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    top: calc(100% - 1.8rem);

    &--error {
      color: var(--bs-danger);
    }
  }
}

.react-select {
  &__input {
    z-index: 2;
  }

  &.extend {
    .react-select__menu {
      min-width: 310px;
    }
  }

  .react-select__ {
    &indicator {
      transform: rotate(0deg);
      transition: transform 0.15s ease-in-out;
    }

    &indicator-separator {
      display: none;
    }

    &value-container {
      padding: 0;

      // transform: translateY(5px);
    }

    &indicators {
      width: 26px;

      // transform: translate(2px, 5px);
    }

    &single-value {
      color: var(--bs-primary);
      max-width: 690px; // TODO: fix for Firefox

      @include body1;
    }

    &control {
      border: none;
      border-color: transparent;
      border-bottom: 0.75px solid var(--bs-primary);
      border-radius: 0;
      box-shadow: none;
      min-height: auto;

      &--is-focused {
        border-top: none;
        border-right: none;
        border-left: none;
      }

      &--menu-is-open {
        .react-select__indicator {
          transform: rotate(180deg);
          transition: transform 0.15s ease-in-out;
        }
      }
    }
  }

  &.react-select--is-disabled {
    .react-select__control {
      border-color: var(--bs-gray-400);
    }
  }

  &.react-select--error {
    .react-select__control {
      border-color: var(--bs-danger);
    }
  }
}

div.react-select__menu {
  margin: 2px 0;

  .react-select__ {
    &menu-list {
      background-color: var(--bs-gray-200);
    }

    &option {
      padding: 4px 12px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;

      &--is-focused,
      &--is-hovered {
        /* stylelint-disable-next-line */
        background-color: rgba(var(--bs-primary-rgb), 0.15);
      }

      // &:hover {
      //   /* stylelint-disable-next-line */
      //   background-color: rgba(var(--bs-primary-rgb), 0.15);
      // }

      &--is-selected {
        background-color: var(--bs-primary);

        &:hover,
        &:focus {
          background-color: var(--bs-primary);
        }
      }
    }
  }
}
