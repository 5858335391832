// CONTAINER
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1180px,
  xxl: 1345px,
  xxxl: 1400px,
);

// COLOR
$red: #bd1b22;
$blue: #033046;
$cyan: #1da1de;
$purple: #802871;
$orange: #e37252;
$olive: #6b6b59;
$green: #328d3c;
$gray-100: #f9f9f3;
$gray-200: #f3f3f3;
$gray-400: #e5e4e3;
$gray-600: #6b7a7f;
$custom-colors: (
  'olive': $olive,
);
$secondary: $orange;

// TYPOGRAPHY
$font-family-sans-serif: 'Inter', 'Helvetica Neue', arial, sans-serif;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$link-decoration: none;
$font-size-base: 0.9375rem; // 15px
$h1-font-size: calc($font-size-base * 32 / 15); // 32px
$h2-font-size: calc($font-size-base * 1.6); // 24px
$h3-font-size: calc($font-size-base * 1.2); // 18px
$body1: $font-size-base; // 15px
$body2: calc($font-size-base * 13 / 15); // 13px
$body3: calc($font-size-base * 0.8); // 12px
$custom-font-size: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  body1: $body1,
  body2: $body2,
  body3: $body3,
);

// NAVBAR
$nav-link-color: #fff;
$navbar-dark-color: $blue;
$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 0.75rem;
$dropdown-border-radius: 0;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

// Body
$body-bg: #f9f9f9;
$body-color: #023047;

// Accordion
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-icon-color: $blue;
$accordion-icon-active-color: #fff;
$accordion-icon-transform: none;
$accordion-button-icon-default: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
$accordion-button-active-icon-default: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
$accordion-button-icon: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='58.251' height='26.698' viewBox='0 0 58.251 26.698' fill='#{$accordion-icon-color}'><g transform='translate(-572.973 -899.305)' style='isolation:isolate'><path d='M586.322,899.3h31.553a13.349,13.349,0,0,1,0,26.7H586.322a13.349,13.349,0,1,1,0-26.7ZM617.875,925a12.349,12.349,0,0,0,0-24.7H586.322a12.349,12.349,0,0,0,0,24.7Z' /><path d='M603.281,911.472h4.4v2.365h-4.4v4.4h-2.365v-4.4h-4.4v-2.365h4.4v-4.4h2.365Z'/></g></svg>");
$accordion-button-active-icon: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='58.251' height='26.698' viewBox='0 0 58.251 26.698' fill='#{$accordion-icon-active-color}'><g transform='translate(-572.973 -827.305)' style='isolation:isolate'><path d='M586.322,827.3h31.553a13.349,13.349,0,0,1,0,26.7H586.322a13.349,13.349,0,1,1,0-26.7ZM617.875,853a12.349,12.349,0,0,0,0-24.7H586.322a12.349,12.349,0,0,0,0,24.7Z' /><path d='M603.281,839.472h4.4v2.365H596.518v-2.365h6.763Z'/></g></svg>");

// Modal
$modal-content-border-radius: 0;
$modal-xl: 1148px;
$modal-content-bg: $gray-200;
$modal-content-color: $blue;
$btn-close-color: #063045;
$btn-close-opacity: 0.75;
$btn-close-hover-opacity: 1;
$popover-arrow-width: 0;
$popover-arrow-height: 0;
$popover-max-width: 300px;
$zindex-popover: 9999;
$input-btn-focus-width: 0.1rem;
