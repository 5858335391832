.container {
  display: flex;
  box-shadow: 0 3px 6px 2px rgb(0 0 0 / 16%);

  .main {
    flex: 1;
  }

  .complianceRate {
    border-left: 1px solid var(--bs-primary);
    width: 26.5%;
    background-color: var(--bs-gray-200);
  }
}
