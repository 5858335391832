@import 'styles/mixins';

.wrapper {
  height: auto;
  max-height: 98vh;
  position: sticky;
  top: 1vh;
  box-shadow: var(--shadow);

  .body {
    height: auto;
    max-height: calc(98vh - 41px);
    overflow: auto;

    @include custom-scrollbar;
  }

  .filterView {
    width: 170px;
  }
}

.wrapperCollapsed {
  position: sticky;
  top: 1vh;
}

.header {
  background: var(--bs-orange);
  color: var(--bs-white);
  padding: 0 7px;

  .content {
    border-bottom: 1.5px solid var(--bs-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.toggleBtn {
  padding: 0;
  height: 40px;
  width: 40px;
  color: var(--bs-white);
  transform: translateX(-7px);

  svg {
    width: 22px;
    height: 22px;
  }

  &.collapsed {
    width: 42px;
    height: 42px;
    border-radius: 9px;
    transform: none;
  }
}
