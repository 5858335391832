@import 'styles/variables';
@import 'styles/typography';

.header {
  background-color: var(--bs-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;

  .info {
    display: flex;
    align-items: center;
    color: var(--bs-white);

    svg {
      fill: currentcolor;
      width: 71px;
      height: 71px;
    }
  }

  .name {
    @include h3;

    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    line-height: 1.1;
  }

  .action {
    flex-shrink: 0;

    :global {
      .react-select {
        width: 175px;

        &__control {
          background-color: transparent;
          border-bottom-color: var(--bs-white);
        }

        &__single-value,
        &__input-container,
        &__indicator {
          color: var(--bs-white);
        }
      }
    }
  }
}
