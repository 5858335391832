.wrapper {
  margin-bottom: 100px;
  transform: translateY(-53px);
  position: relative;
  z-index: 2;
}

.toggleMeasureDisplay {
  text-align: left;
  display: inline-block;
  margin-bottom: 30px;

  :global {
    .form-check-input {
      width: 16px;
      height: 16px;
      border-radius: 0;
      border-color: var(--bs-primary);
    }
  }
}
