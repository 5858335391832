@import 'styles/typography';

.requirements {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .end {
    text-align: right;
  }
}

.wrapper {
  position: relative;

  .sentinel {
    position: absolute;
    bottom: -35px;
    left: 0;
    height: 20px;
    visibility: hidden;
  }
}

.productNameSection {
  @include h2;

  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  gap: 5%;
  margin-bottom: 5px;
  position: sticky;
  top: 115px;
  z-index: 4;

  &.sticky {
    background-color: var(--bs-white);
  }

  .titleArea {
    max-width: 45%;
    display: flex;
    gap: 10px;
    align-items: center;

    &.flipped {
      flex-direction: row-reverse;
    }

    .titleName {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.importRequirement {
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);

  p {
    margin: 0;
  }

  .measures {
    color: var(--bs-secondary);
  }
}
