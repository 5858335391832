@import 'styles/typography';

.navigateBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--bs-orange);
  font-weight: var(--font-weight-medium);
  position: relative;

  svg {
    margin-left: 1.5rem;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-top: 2px solid var(--bs-orange);
    width: 18px;
    height: 15px;
    background: var(--bs-gray-100);
    transform: rotate(-135deg) translate(-1px, 11px);
  }

  &:hover,
  &:focus {
    border: 2px solid var(--bs-orange);
    background: var(--bs-orange);
    color: var(--bs-white);

    svg {
      fill: var(--bs-white);
    }
  }

  &:last-child {
    margin-left: 10px;
  }
}

.confirm {
  border-radius: 0;
  border: none;
  box-shadow: var(--shadow);

  .body {
    @include body1;

    text-align: center;
    min-width: 250px;
  }

  .actions {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      padding-left: 22px;
      padding-right: 24px;
    }
  }
}
