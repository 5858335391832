.compare-nav {
  .nav-area {
    margin-bottom: 24px;
  }

  .nav-pills {
    justify-content: space-between;

    .nav-link {
      background-color: var(--bs-gray-200);
      color: var(--bs-olive);
      font-weight: var(--font-weight-medium);
      border-radius: 0;
      clip-path: var(--btn-polygon);

      &.active {
        color: var(--bs-white);
        background-color: var(--bs-secondary);
      }
    }
  }

  &.full-height {
    display: flex;
    flex-direction: column;
    flex: 1;

    .tab-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      & > .active {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;

    .nav-area {
      margin: 0;
      margin-right: 20px;

      .nav-pills {
        flex-direction: column;
        gap: 12px;

        .nav-item {
          width: fit-content;
        }
      }
    }
  }
}
