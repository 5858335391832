.wrapper {
  margin: 4rem 0 8rem;
  display: flex;
  justify-content: space-around;
}

.form {
  width: 36.3%;
}

.profileManagement {
  width: 41%;
}
