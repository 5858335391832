.container {
  margin-bottom: 20px;
}

.sectionHeader {
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .item {
    --spacing: 14px;

    flex: 1 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);

    .header {
      background-color: var(--bs-gray-400);

      :global {
        .section-header-info {
          color: var(--bs-primary);
        }
      }

      &:not(.reverse) {
        padding-right: var(--spacing);
      }

      &.reverse {
        padding-left: var(--spacing);
        flex-direction: row-reverse;

        :global {
          .section-header-info {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}
