.radioCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;

  .input {
    flex: 1;
  }

  .loading {
    position: absolute;
    right: -5px;
  }

  :global {
    .radioGroup {
      white-space: nowrap;
    }
  }
}
