.overlay {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
  z-index: 9999;

  .spinner {
    width: 50px;
    height: 50px;
  }
}
