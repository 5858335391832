.filterBtnTrigger {
  transform: translateX(5px);
  padding: 0.25rem;
}

.topActions {
  display: flex;
  justify-content: space-between;
}

.bottomActions {
  margin-top: 10px;
  text-align: right;

  .submitBtn {
    margin-right: 5px;
  }

  :global {
    .btn {
      min-width: 60px;
    }
  }
}

.listWrapper {
  overflow: auto;
  margin-top: 5px;
  max-height: 300px;
  margin-left: -3px;
}

.list {
  width: max-content;
  min-width: 100%;

  .checkbox {
    display: flex;
    gap: 5px;

    &:hover {
      background-color: var(--bs-gray-300);
    }

    :global {
      .form-check-input {
        --checkbox-size: 15px;

        width: var(--checkbox-size);
        height: var(--checkbox-size);
        flex-shrink: 0;
        margin-left: calc(-1.5em + 3px);
      }

      .form-check-label {
        white-space: nowrap;
        flex-grow: 1;
      }
    }
  }
}
