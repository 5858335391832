@import 'styles/typography';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .dropdown {
    margin-bottom: 30px;
    z-index: 3;
  }

  .totalCount {
    font-weight: var(--font-weight-bold);

    .title {
      @include h1;

      position: relative;

      .decoration {
        position: absolute;
        top: -14px;
        left: -33px;
      }

      .content {
        position: relative;
        z-index: 2;
      }
    }

    .number {
      font-size: 5rem;
      line-height: 1;
      text-align: center;
    }
  }

  .separator {
    border-bottom: 1.5px solid var(--bs-primary);
    position: relative;
    height: 40px;
    margin-bottom: 20px;
    width: 72%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      border-left: 1.5px solid var(--bs-primary);
      height: 100%;
    }
  }

  .countByTypes {
    width: 100%;
    display: grid;
    grid-template-columns: 23% 23% 23%;
    gap: 0 6.1%;
    justify-content: center;

    .countOfType {
      display: grid;
      align-content: space-between;
    }

    .info {
      display: grid;
      grid-template-columns: 72px 119px auto;
      justify-content: space-between;

      .image {
        flex: 0 0;
      }

      .title {
        margin-top: 13px;
        text-transform: uppercase;
        font-weight: var(--font-weight-medium);
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
      }

      .count {
        margin-top: 7px;
        color: var(--bs-orange);
        display: flex;
        flex-direction: column;
        align-items: center;

        .number {
          font-size: 2.75rem;
          font-weight: var(--font-weight-bold);
          line-height: 1;
        }

        .unit {
          @include body3;

          font-weight: var(--font-weight-medium);
          text-transform: uppercase;
        }
      }
    }

    .action {
      margin-top: 17px;
      text-align: end;

      :global {
        .btn {
          min-width: 119px;
        }
      }
    }
  }
}
