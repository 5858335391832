.oddItem {
  background-color: var(--bs-gray-400);
}

.measureDetail {
  padding: 0 23px 23px;
}

.details {
  dt {
    line-height: 2;
  }

  dd {
    white-space: pre-wrap;
    padding-bottom: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--bs-primary);
    }
  }
}
