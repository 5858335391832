/* stylelint-disable no-descending-specificity */

.ntm-accordion {
  .ntm-accordion-header {
    margin-bottom: 0;
    color: var(--bs-white);
    background-color: var(--bs-orange);

    .accordion-button {
      padding: 0.625rem 1.5rem;
      font-weight: var(--font-weight-medium);

      @include h2;

      &::after {
        background-image: $accordion-button-active-icon-default;
        width: 1.25rem;
        height: 1.25rem;
        transform: rotate(-180deg);
        transition: transform 0.2s ease-in-out;
      }

      &.collapsed {
        &::after {
          background-image: $accordion-button-icon-default;
          transform: rotate(0deg);
        }
      }
    }
  }
}

.accordion {
  --first-level-spacing: 2.8125rem;
  --border: 1.5px solid var(--bs-primary);

  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion-button {
    font-weight: var(--font-weight-medium);
    border: none;
    box-shadow: none;

    &::after {
      background-position: center;
      width: 3.5rem;
      height: 1.625rem;
      background-size: contain;
    }
  }

  .accordion-item {
    border: none;
    border-top: var(--border);

    &:not(.first-level) {
      margin-left: var(--first-level-spacing);
      background-color: var(--bs-gray-200);
    }

    &:last-of-type {
      border-bottom: var(--border);
    }
  }

  .first-level {
    &.accordion-item {
      background-color: var(--bs-white);
    }

    &.accordion-header {
      .accordion-button {
        min-height: 77px;
        background-color: var(--bs-white);
        padding-left: var(--first-level-spacing);
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;

        svg {
          margin-right: 1rem;
        }

        &:not(.collapsed) {
          background-color: var(--bs-primary);
          color: var(--bs-white);

          svg {
            fill: var(--bs-white);
          }
        }
      }
    }

    &.accordion-body {
      .content {
        padding: 0 var(--first-level-spacing);
        margin-bottom: var(--bs-accordion-body-padding-y);
      }

      .accordion {
        margin-bottom: var(--bs-accordion-body-padding-y);

        &:first-child {
          margin-top: calc(var(--bs-accordion-body-padding-y) * -1);

          .accordion-item:first-child {
            border-top: none;
          }
        }

        &:last-child {
          margin-bottom: calc(var(--bs-accordion-body-padding-y) * -1);

          .accordion-item:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  :not(.first-level) {
    .accordion-button {
      background-color: var(--bs-gray-200);
      box-shadow: none;
      padding: 0.625rem 1.4rem;

      &::after {
        width: 2.375rem;
      }

      &:not(.collapsed) {
        border-bottom: var(--border);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.148' height='18.123' viewBox='0 0 39.148 18.123'%3E%3Cg transform='translate(0.5 0.5)' style='isolation:isolate'%3E%3Cg style='isolation:isolate'%3E%3Cpath d='M603.059 899.8H582.035a8.562 8.562 0 1 0 0 17.123h21.025a8.562 8.562 0 1 0 0-17.123Z' transform='translate(-573.473 -899.805)' fill='%23033046'/%3E%3Cpath d='M582.035 899.3h21.025a9.062 9.062 0 1 1 0 18.123H582.035a9.062 9.062 0 1 1 0-18.123Zm21.025 17.123a8.062 8.062 0 1 0 0-16.123H582.035a8.062 8.062 0 1 0 0 16.123Z' transform='translate(-573.473 -899.805)' fill='%23023047'/%3E%3C/g%3E%3Cg transform='translate(15.355 7.774)'%3E%3Cpath d='M603.955 910v1.576h-7.437V910Z' transform='translate(-596.518 -910.005)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }

    .highlight {
      .accordion-button {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .no-content.accordion-item {
    .accordion-header {
      .accordion-button {
        &::after {
          background-image: none;
        }
      }
    }

    &:last-of-type {
      .accordion-header {
        .accordion-button {
          border-bottom: none;
        }
      }
    }
  }
}

/* stylelint-enable no-descending-specificity */
