@import 'styles/typography';

.wrapper {
  padding: 0;
}

.headerCardWrapper {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.headerCard,
.profileCard {
  &.card {
    background-color: var(--bs-gray-200);
  }
}

.message {
  padding: 1.125rem;
  text-align: center;
}

.deleteConfirm {
  border-radius: 0;
  border: none;
  box-shadow: var(--shadow);

  .body {
    @include body1;

    text-align: center;
    min-width: 250px;
  }

  .actions {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      padding-left: 22px;
      padding-right: 24px;
    }
  }
}

.headerCard {
  padding: 1.125rem;
  margin-bottom: 40px;

  .title {
    @include h2;

    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
  }
}

.profileList {
  list-style: none;
  margin: 0;
  padding: 0;

  .profileItem {
    display: flex;
    padding: 14px;
    border: none;
    width: 100%;
    text-align: start;
    position: relative;

    .delete {
      display: none;
    }

    &:not(:last-child) {
      border-bottom: 1.5px solid var(--bs-primary);
    }

    &:hover,
    &:focus-within {
      background-color: var(--bs-white);

      .delete {
        display: block;
      }

      :global {
        .editable-content {
          &::after {
            visibility: visible;
          }
        }
      }
    }

    .index {
      @include h1;

      width: 65px;
      flex: 0 0 65px;
      line-height: 1;
    }

    .content {
      flex: 1;
    }

    .topRow {
      display: flex;
      flex-direction: column;

      .profileName {
        @include h3;

        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        min-height: 35px;
      }

      .delete {
        .deleteBtn {
          border: none;
          background: transparent;
          border-radius: 2px;

          &:focus,
          &:hover {
            background-color: var(--bs-gray-200);
          }
        }
      }

      .countryName {
        text-transform: uppercase;
      }
    }

    .complianceRate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.75rem;

      .complianceRateLabel {
        font-weight: var(--font-weight-medium);
      }

      .percentage {
        @include h3;

        font-weight: var(--font-weight-bold);
        min-width: 50px;
        text-align: end;
      }
    }
  }
}
