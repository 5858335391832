@import 'styles/variables';

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loginArea {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-1.5rem);

  .loginBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    border: 2px solid var(--bs-orange);
    font-weight: var(--font-weight-medium);
    position: relative;

    svg {
      margin-left: 1.5rem;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      border-top: 2px solid var(--bs-orange);
      width: 18px;
      height: 15px;
      background: var(--bs-white);
      transform: rotate(-135deg) translate(-1px, 11px);
    }

    &:hover,
    &:focus {
      border: 2px solid var(--bs-orange);
      background: var(--bs-orange);
      color: var(--bs-white);

      svg {
        fill: var(--bs-white);
      }
    }
  }
}
