@import 'styles/typography';

.banner {
  height: 152px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  picture {
    position: absolute;
    top: 0;
  }

  .title {
    z-index: 1;
    position: relative;
    text-align: center;

    @include h1;
  }
}

.body {
  margin-top: 43px;
  margin-bottom: 75px;
}
