@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  width: 100%;
  overflow: auto;

  @include custom-scrollbar;

  .item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--bs-primary);
    }
  }

  .title {
    text-align: center;
    font-weight: var(--font-weight-bold);
    padding: 5px 0;
    position: sticky;
    top: 0;
    background-color: var(--bs-white);
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .col {
      width: 50%;

      &:first-child {
        padding-right: 0.5rem;
        border-right: 1px solid var(--bs-primary);
      }

      &:last-child {
        padding-left: 0.5rem;
      }
    }
  }
}
