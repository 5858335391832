@import 'styles/typography';

.section {
  margin-bottom: 5px;
  position: relative;

  .sentinel {
    position: absolute;
    visibility: hidden;
  }

  .header {
    color: var(--bs-white);
    justify-content: center;
    position: sticky;
    top: 185px;
    z-index: 2;

    :global {
      .section-header-info {
        max-width: 80%;
        z-index: 1;
      }

      .section-header-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.875rem;

        .measure-count {
          font-size: 2.75rem;
        }
      }
    }
  }

  &:global(.section-has-sticky) {
    :global {
      .section-header-info {
        .section-header-icon {
          display: none;
        }
      }

      .section-header-content {
        .measure-count-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;

          @include h2;
        }

        .measure-count {
          @include h2;
        }
      }

      .compare-common-accordion {
        margin-top: 130px;

        .accordion-header {
          position: sticky;
          top: 210px;
          z-index: 1;

          &.is-sticky {
            .content {
              padding-top: 0.3rem;
              padding-bottom: 0.3rem;
            }

            .measure-count-wrapper {
              display: flex;
              align-items: center;
              gap: 5px;

              .measure-count {
                @include h2;
              }
            }

            .measureName {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              span {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
