@import 'styles/variables';

.clearAllFilters {
  text-transform: uppercase;
}

.stickyColumn {
  position: sticky;
  right: -1px;
  background-color: var(--bs-white);
  color: var(--bs-primary);
  text-transform: none;
  text-align: center;
  border-left: 1px solid var(--bs-primary);
  width: 300px;
}

.table {
  --odd-row-bg: #eee;

  background: var(--bs-white);
  border: 1px solid var(--bs-primary);
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  min-width: 100%;

  thead {
    tr {
      background-color: var(--bs-primary);
      color: var(--bs-white);
      text-transform: uppercase;

      th {
        font-weight: var(--font-weight-medium);
        padding: 5px 10px;
        border-right: 1px solid var(--bs-white);

        &:last-child {
          border-right: none;
        }

        &.sortable {
          cursor: pointer;
          user-select: none;
        }

        .headerCell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.5rem;
        }

        .complianceCheckCell {
          color: var(--bs-white);
        }
      }

      &.complianceRow {
        background-color: var(--bs-orange);
        color: var(--bs-primary);
        text-transform: initial;

        .headerCell {
          justify-content: center;
        }
      }

      &.toolbar {
        background-color: var(--bs-orange);
        text-transform: none;

        th {
          padding: 0;
        }

        .container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 30px;

          input {
            padding-left: 0.5rem;
          }

          .endActions {
            display: flex;
            gap: 1rem;

            .downloadBtn {
              color: var(--bs-white);

              span {
                color: var(--bs-white);
              }

              svg {
                fill: var(--bs-white);
              }
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n + 1) {
        background-color: var(--odd-row-bg);

        .stickyColumn {
          background-color: var(--odd-row-bg);
        }
      }
    }

    td {
      padding: 5px 10px;
      border-right: 1px solid var(--bs-primary);
      white-space: pre-wrap;
      vertical-align: top;
    }
  }

  &.sticky {
    position: relative;

    thead {
      position: sticky;
      top: -1px;
    }
  }
}
