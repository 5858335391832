.wrapper {
  display: flex;
  justify-content: center;

  .active {
    text-decoration: underline;
  }

  :global {
    .btn {
      font-size: 0.8125rem;
    }
  }
}
