.card {
  padding: 10px;
  text-align: initial;

  .headerWrapper {
    justify-content: space-between;
    flex: 1;
  }

  .body {
    margin-right: -10px;
  }
}

.details {
  padding: 0 23px 23px;

  dt {
    line-height: 2;
  }

  dd {
    white-space: pre-wrap;
    padding-bottom: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--bs-primary);
    }
  }
}
