.unauthenticated {
  margin: 3rem 0 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  border: 2px solid var(--bs-orange);
  font-weight: var(--font-weight-medium);
  position: relative;

  svg {
    margin-left: 1.5rem;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-top: 2px solid var(--bs-orange);
    width: 18px;
    height: 15px;
    background: var(--bs-white);
    transform: rotate(-135deg) translate(-1px, 11px);
  }

  &:hover,
  &:focus {
    border: 2px solid var(--bs-orange);
    background: var(--bs-orange);
    color: var(--bs-white);

    svg {
      fill: var(--bs-white);
    }
  }

  &:active,
  &:disabled {
    &::after {
      border-color: var(--bs-primary);
    }
  }

  &.modal {
    &::after {
      background: var(--bs-modal-bg);
    }
  }

  &.body {
    &::after {
      background: var(--bs-body-bg);
    }
  }
}
