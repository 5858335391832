@import 'styles/typography';

.divider {
  position: relative;
  margin: 0;

  &::after {
    content: '';
    display: block;
    height: 77px;
    width: 2px;
    background-color: var(--bs-primary);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 137px;

  .logoWithText {
    display: flex;
    align-items: center;
  }

  .logo {
    height: 77px;
    display: inline-block;
  }

  .divider {
    margin: 0 22px 0 32px;
  }

  .title {
    @include h2;

    line-height: 1;
    max-width: 280px;
    text-decoration: none;
    color: var(--bs-gray);
    text-transform: uppercase;
  }
}

.rightRegion {
  display: flex;
  align-items: center;

  .description {
    @include h3;

    text-transform: uppercase;
    color: var(--bs-primary);
    line-height: 1;
    margin-right: 21px;
  }

  .divider {
    margin: 0 21px 0 29px;
  }

  .action {
    text-align: center;
  }

  .loginBtn {
    font-size: 0.75rem;
    font-weight: bold;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 5px;
  }

  .dropdown {
    text-align: center;
  }

  .userBtn {
    font-size: 0.75rem;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    span {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
