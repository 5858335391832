@import 'styles/variables';
@import 'styles/typography';

.wrapper {
  text-align: center;
  color: var(--bs-primary);

  .message {
    @include h2;

    font-weight: var(--font-weight-bold);
    margin-top: 1rem;
  }
}
