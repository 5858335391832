.frontPage {
  transform: translateY(1.5rem);
}

.profileName {
  text-transform: uppercase;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 5px;
}

.countryName {
  text-transform: uppercase;
}

.hasDivider {
  border-bottom: 1.5px solid var(--bs-gray-500);
}
