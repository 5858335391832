@import 'styles/typography';

.header {
  background: var(--bs-white);
  border: 1px solid var(--bs-orange);
  text-align: center;
  padding: 8px 8px 4px;
  position: sticky;
  top: 150px;
  z-index: 3;

  h2 {
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    color: var(--bs-orange);
    margin: 0;

    @include h3;
  }
}
