@import 'styles/variables';
@import 'styles/typography';

$left-column-width: 178px;

.oddRow {
  /* stylelint-disable-next-line */
  background-color: rgba(var(--bs-dark-rgb), 0.05);
}

.highlight {
  background-color: var(--bs-yellow);
}

.itemHeader {
  display: flex;
  align-items: baseline;
  gap: 1.5rem;
}

.sectionContainer:not(:last-of-type) {
  margin-bottom: 50px;
}

.section {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  .code {
    font-weight: var(--font-weight-bold);
    background-color: var(--bs-primary);
    color: var(--bs-white);
    width: $left-column-width;
    padding: 2.5px 9px;
    flex-shrink: 0;

    @include h2;
  }

  .name {
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);

    @include h3;
  }
}

.level {
  .codeContainer {
    width: $left-column-width;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
  }

  .code {
    --code-bg: #78909f;

    background-color: var(--code-bg);
    padding: 0 10px;
    font-weight: var(--font-weight-bold);
    color: var(--bs-white);
    width: fit-content;
    min-width: 55px;
    text-align: center;
    margin: 4px 0;

    @include h2;
  }

  .name {
    margin: 8px 0;
    text-align: start;
  }

  .level1 {
    .codeContainer {
      width: $left-column-width;
    }

    .code {
      @include h2;
    }

    .name {
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      margin: 8px 0;

      @include h3;
    }
  }

  .level2 {
    .code {
      min-width: 70px;
    }

    :global {
      .accordion-item:last-child {
        padding-bottom: 0.5rem;
      }
    }
  }

  .level3 {
    .code {
      min-width: 100px;
    }
  }

  .deepLevel {
    padding-left: calc($left-column-width + 1.5rem);

    .codeContainer {
      width: auto;

      .code {
        --code-bg: #bac3c9;

        background-color: var(--code-bg);
        color: var(--bs-primary);
        font-weight: var(--font-weight-normal);
        padding: 4px 10px;

        @include h3;
      }
    }
  }

  .leafLevel {
    button {
      // padding-left: calc($leftColumnWidth + 3rem);
      border: none;
      width: 100%;
      background: transparent;
    }

    .codeContainer {
      .code {
        --code-bg: #e4d5d0;

        color: var(--bs-orange);
        min-width: 150px;
      }
    }

    .name {
      color: var(--bs-orange);
      line-height: 18px;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  :global {
    .accordion-button {
      &:focus,
      &:hover {
        /* stylelint-disable-next-line */
        background-color: rgba(var(--bs-primary-rgb), 0.15);
      }
    }
  }
}
