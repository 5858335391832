.no-spinner {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  appearance: textfield;
}

.checkbox-group {
  .form-check {
    display: flex;

    label {
      padding-left: 15px;
    }
  }

  .form-check-input {
    margin-top: 0;
    border-radius: 0;
    border: 1.5px solid var(--bs-primary);
    width: 25px;
    height: 25px;
    flex-shrink: 0;
  }

  .highlight-label {
    .form-check-label {
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
    }
  }

  &.indeterminate {
    .form-check {
      .form-check-input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
        background-color: var(--bs-primary);
      }
    }
  }
}
