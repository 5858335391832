@import 'styles/variables';

.footer {
  margin-top: 4rem;
  text-align: center;
  font-weight: var(--font-weight-medium);

  .title {
    margin-bottom: 1.375rem;
  }

  .ctaBtn {
    border-radius: 9999px;
  }
}
