@import 'styles/typography';

.headerArea {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: sticky;
  top: 115px;
  z-index: 5;

  .item {
    flex: 1 0 50%;
    max-width: calc(50% - 0.5rem);

    &:last-child {
      text-align: end;
    }
  }
}

.itemContainer {
  .header,
  .titleArea {
    padding: 0.5rem 1rem;
  }

  .header {
    background-color: var(--bs-white);
    border: 1px solid var(--bs-info);
    color: var(--bs-info);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);

    h2 {
      @include h3;

      line-height: 1.1;
      margin: 0;
    }
  }

  .titleArea {
    background-color: var(--bs-info);
    color: var(--bs-white);
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 10px;

    @include h2;

    &.flipped {
      flex-direction: row-reverse;
    }

    .titleName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
