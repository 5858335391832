@import 'styles/variables';

.btn {
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  position: fixed;
  bottom: 24px;
  right: 16px;
  width: 45px;
  height: 45px;
  z-index: 10;
  padding: 0;
  background: #e0e3e9;
  border: none;
  border-radius: 10px;
}
