@import 'styles/variables';
@import 'styles/typography';

.footer {
  @include body3;

  background-color: var(--bs-primary);
  color: var(--bs-white);
  padding: 50px 0 0;
  min-height: 100px;

  :global {
    a {
      color: white;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    #gg-translate-element {
      margin-bottom: 0.5rem;
    }

    svg {
      path {
        fill: var(--bs-white) !important;
      }
    }
  }

  .logo {
    max-width: 175px;
  }

  .contact {
    p {
      margin-bottom: 0.25rem;
    }

    .socialIcons {
      display: flex;
      margin-top: 0.7rem;

      .icon {
        &:not(:last-child) {
          margin-right: 0.8rem;
        }
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .rightSection {
    font-weight: var(--font-weight-medium);
    margin-left: 14%;

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.copyright {
  margin: 30px auto;
  display: flex;
  justify-content: center;
  font-weight: var(--font-weight-medium);
}
