@import './variables';

@mixin h1() {
  font-size: $h1-font-size;
  font-weight: var(--font-weight-bold);
}

@mixin h2() {
  font-size: $h2-font-size;
}

@mixin h3() {
  font-size: $h3-font-size;
}

@mixin body1() {
  font-size: $body1;
}

@mixin body2() {
  font-size: $body2;
}

@mixin body3() {
  font-size: $body3;
}

.text-error {
  font-size: 0.75rem;
  color: var(--bs-danger);
}
