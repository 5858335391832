@use 'sass:map';

.sub-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: map.get($container-max-widths, 'xl');
}

.sub-container-xxxl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: map.get($container-max-widths, 'xxxl');
}
