.compareCommonAccordion {
  :global {
    .accordion-item {
      border-bottom: 1.5px solid var(--bs-primary);
    }

    .accordion-button {
      background-color: var(--bs-white);
      z-index: 0;

      &:not(.collapsed) {
        background-color: var(--bs-gray-200);
      }

      &:hover,
      &:focus {
        background-color: var(--bs-gray-300);
      }

      .content {
        padding: 0.75rem 0.875rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: var(--font-weight-medium);

        .measureName {
          text-align: center;

          span {
            display: block;
            margin-bottom: 0.5rem;
          }
        }

        .measure-count {
          font-weight: var(--font-weight-normal);
        }
      }
    }

    .accordion-collapse {
      background-color: var(--bs-gray-200);
      padding: 10px;
    }
  }

  .compareLayout {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .item {
      max-width: calc(50% - 10px);
      padding: 10px;
    }
  }
}
