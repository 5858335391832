.details {
  dt {
    line-height: 2;
  }

  dd:not(:last-of-type) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--bs-primary);
    white-space: pre-wrap;
  }
}

.measureDetail {
  --padding-space: 23px;

  padding-left: var(--padding-space);
  padding-right: var(--padding-space);
  background-color: var(--bs-white);

  &.selfAssess {
    padding: 0;

    .details {
      dt {
        padding-top: 15px;
      }

      dd {
        padding-bottom: 15px;
        margin-bottom: 0;
      }

      dt,
      dd {
        padding-left: var(--padding-space);
        padding-right: var(--padding-space);
      }
    }
  }
}
