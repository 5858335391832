.detailPanel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  background-color: var(--bs-gray-200);
  margin: 2rem 0.5rem;

  > div {
    &:first-child {
      text-align: start;
    }

    &:last-child {
      text-align: end;
    }
  }
}
