@import 'styles/typography';

.card {
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;

  .cardTitle {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .cardBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .decoration {
    position: absolute;
    top: -10px;
    left: -10px;
  }

  .title {
    @include h1;

    color: var(--bs-primary);
    font-weight: var(--font-weight-bold);
    margin-left: 28px;
    margin-bottom: 35px;
    transform: translateY(8px);
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    &::after {
      content: '.';
    }
  }

  .icon {
    height: 47px;
  }
}
