.accordion {
  font-weight: var(--font-weight-medium);

  .title {
    background-color: var(--bs-gray-200);
    padding: 19px 23px;

    > * {
      margin: 0;
    }

    &::marker {
      display: none;
      content: '';
    }
  }

  .details {
    padding: 15px 23px;
    background-color: var(--bs-white);
  }
}

.container {
  display: flex;
  box-shadow: 0 3px 6px 2px rgb(0 0 0 / 16%);

  .main {
    flex: 1;
  }

  .complianceRate {
    border-left: 1px solid var(--bs-primary);
    width: 26.5%;
    background-color: var(--bs-gray-200);
  }
}
