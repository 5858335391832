@mixin custom-scrollbar() {
  scrollbar-width: thin;
  scrollbar-color: var(--bs-primary) var(--bs-gray-300);

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: 1px solid var(--bs-primary);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
  }
}
