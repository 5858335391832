.btn-corner-cut {
  clip-path: var(--btn-polygon);
  font-weight: var(--font-weight-medium);

  &.btn-uppercase {
    text-transform: uppercase;
  }
}

.btn-outline-primary {
  &:hover,
  &:focus {
    background-color: var(--bs-primary);
    color: var(--bs-white);

    svg {
      fill: var(--bs-white);
    }
  }
}

.btn-outlined {
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  border-width: 2px;
  border-style: solid;
  border-radius: 9999px;

  &.btn-outline-toggle {
    border-color: var(--bs-primary);
    font-weight: var(--font-weight-normal);
    border-width: 1.5px;
    padding: 0.25rem 1.25rem;
    line-height: 1.1;

    &:hover,
    &:focus {
      background-color: var(--bs-gray-300);
      border-color: var(--bs-primary);
    }

    &.active,
    &:active {
      border-color: var(--bs-orange);
      background-color: var(--bs-orange);
      color: var(--bs-white);
    }
  }

  &.btn-sm {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    padding: 0.25rem 0.5rem;
  }
}

/* stylelint-disable no-descending-specificity */
.btn-check:active + .btn-outline-toggle,
.btn-check:checked + .btn-outline-toggle,
.btn-outline-toggle.active,
.btn-outline-toggle:active {
  border-color: var(--bs-orange);
  background-color: var(--bs-orange);
  color: var(--bs-white);
}
/* stylelint-enable no-descending-specificity */

.btn {
  &.btn-secondary {
    color: var(--bs-white);

    &:hover,
    &:focus {
      color: var(--bs-white);
    }
  }

  &[type='submit'] {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
