@import 'styles/typography';
@import 'styles/variables';

.form {
  display: flex;
  flex-direction: column;
  flex: 1;

  .formBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .formGroup {
    display: flex;
    align-items: flex-start;

    label {
      @include body2;

      margin-right: 5px;
      flex: 0 0 80px;
      max-width: 80px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-weight: var(--font-weight-medium);
      transform: translateY(9px);

      svg {
        margin-right: 5px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .formField {
    flex: 1;
    overflow: hidden;
  }

  .formAction {
    text-align: center;
  }

  :global {
    select {
      width: 100%;
    }
  }

  &.horizontal {
    flex-direction: row;
    justify-content: space-between;

    .formBody {
      flex-direction: row;
      justify-content: space-between;
    }

    .formGroup {
      flex: 1 1 31%;
      max-width: 31%;
      margin-bottom: 0;
    }

    .formAction {
      margin-left: 1rem;
    }
  }
}
