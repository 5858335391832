.sorting {
  text-transform: none;

  :global {
    .dropdown-toggle {
      padding: 10px 5px;
      background-color: transparent;
      border: none;

      &:hover,
      &:focus {
        /* stylelint-disable-next-line */
        background-color: rgba(var(--bs-light-rgb), 0.1);
      }

      &::after {
        display: none;
      }
    }
  }

  .selectedOption {
    color: var(--bs-orange);
    font-weight: var(--font-weight-semibold);
  }
}

.menu {
  z-index: 10000;
  width: 290px;

  :global {
    .dropdown-item {
      white-space: normal;
    }
  }
}

.sortingDropdown {
  padding: 2px 5px;
  background: var(--bs-white);
  display: flex;
  align-items: center;
  gap: 10px;
  width: 260px;

  svg {
    flex-shrink: 0;
  }

  :global {
    .select-wrapper {
      flex: 1 1;
      min-width: 120px;
    }

    .react-select__menu {
      z-index: 10000;
    }
  }

  &.inverted {
    background-color: transparent;

    svg {
      fill: var(--bs-white);
    }

    :global {
      .react-select__control {
        background-color: transparent;
        border-bottom-color: var(--bs-white);
      }

      .react-select__single-value {
        color: var(--bs-white);
      }

      .react-select__option {
        color: var(--bs-primary);

        &--is-selected {
          color: var(--bs-white);
        }
      }
    }
  }
}
