.progressWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  .progress {
    border: 1.5px solid var(--bs-primary);
    border-radius: 9999px;
    background-color: transparent;
    min-width: 125px;

    & + label {
      width: 40px;
      text-align: right;
    }

    :global {
      .progress-bar {
        border-radius: 9999px;
      }
    }
  }

  &.inverted {
    label {
      color: var(--bs-white);
    }

    .progress {
      border-color: var(--bs-white);
    }

    :global {
      .progress-bar {
        background-color: var(--bs-white);
      }
    }
  }

  &.info {
    :global {
      .progress-bar {
        background-color: var(--bs-info);
      }
    }
  }

  &.orange {
    :global {
      .progress-bar {
        background-color: var(--bs-orange);
      }
    }
  }
}
