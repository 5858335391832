.cardWrapper {
  filter: drop-shadow(20px 19px 8px rgba(0 0 0 / 8%));

  .card {
    background: var(--bs-white);
  }

  &.hasCornerCut {
    filter: drop-shadow(20px 19px 8px rgba(0 0 0 / 8%));

    .card {
      clip-path: polygon(
        calc(100% - 24px) 0,
        100% 24px,
        100% 100%,
        0 100%,
        0 0
      );
    }
  }
}
