.clearBtn {
  font-size: 0.8rem;
  transform: none;
  filter: grayscale(1);

  &:not(.external) {
    position: absolute;
    right: 0.5rem;
  }

  &.external {
    margin-left: 5px;
  }
}

.searchInput {
  align-items: center;
  display: flex;
  position: relative;

  &.error {
    .input {
      border-color: var(--bs-danger);
    }
  }

  &:not(.fullBorder) {
    :global {
      .form-control {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        background: var(--bs-white);
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  :global(.form-control[type='number']) {
    padding-left: 0.5rem;

    & + .clearBtn {
      right: 0.8rem;
    }
  }
}
