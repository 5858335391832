@import 'styles/typography';
@import 'styles/variables';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/functions';

// 3. Include remainder of required Bootstrap stylesheets
@import 'node_modules/bootstrap/scss/variables';

// 5. Include remainder of required parts
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';

.widgetArea {
  --fallback-color: #e2dcce;

  background-color: var(--fallback-color);
  min-height: 610px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .background {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    z-index: 1;

    .widget {
      display: flex;
      align-self: stretch;
      justify-self: stretch;
      flex: 0 1 31.5%;
      max-width: 31.5%;

      > * {
        min-height: 475px;
        width: 100%;
      }
    }
  }
}

.infoArea {
  padding: 30px 0 115px;

  section {
    margin-bottom: 1.5rem;
  }

  .sectionTitle {
    @include h2;

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--bs-orange);
    font-weight: normal;
    margin-bottom: 0.5rem;

    svg,
    img {
      margin: 0 30px 0 0;
    }
  }

  .sectionDescription {
    @include h3;

    display: block;
    margin: 0.5rem 0;
    font-weight: var(--font-weight-medium);
    line-height: 1.3;
  }

  .videoPlayer {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    text-align: initial;
  }

  .videoPlayerIframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .twitterBlock {
    text-align: center;
  }

  .reverse {
    @include media-breakpoint-up('xl') {
      justify-content: flex-end;
      text-align: end;

      .sectionTitle {
        flex-direction: row-reverse;

        svg,
        img {
          margin: 0 0 0 30px;
        }
      }
    }
  }
}
