.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;

  .icon {
    width: 24px;
  }

  .label {
    margin-top: 3px;
    text-transform: uppercase;
    font-size: 0.5rem;
    color: var(--bs-primary);
  }

  &.inverted {
    background-color: transparent;
    color: var(--bs-white);

    .icon {
      fill: var(--bs-white);
    }

    &:hover,
    &:focus {
      /* stylelint-disable-next-line */
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
