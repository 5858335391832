.compareAccordion {
  background-color: var(--bs-white);

  :global {
    .accordion-collapse {
      background-color: var(--bs-gray-200);
      padding: 10px;

      .container {
        padding: 10px;
      }

      .detailsBody {
        padding-right: 0;
        margin-right: -10px;
      }
    }

    .accordion-header {
      .accordion-button {
        z-index: 0;
        text-transform: uppercase;
        font-weight: var(--font-weight-medium);

        &:hover,
        &:focus {
          background-color: var(--bs-gray-300);
        }

        &:not(.collapsed) {
          background-color: var(--bs-primary);
          color: var(--bs-white);

          svg {
            fill: var(--bs-white);
          }
        }

        .container {
          padding: 9px 0;
          margin: 0 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .accordion-item {
      &:not(:first-child) {
        .accordion-button {
          .container {
            border-top: 1.5px solid var(--bs-primary);
          }
        }
      }
    }
  }
}
