@import 'styles/typography';
@import 'styles/variables';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .formBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .formGroup {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;

    label {
      @include body2;

      margin-right: 5px;
      flex: 0 0 100px;
      max-width: 100px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-weight: var(--font-weight-medium);
      transform: translateY(9px);

      svg {
        margin-right: 1rem;
        width: 16.5px;
      }
    }
  }

  .formField {
    flex: 1;
    overflow: hidden;
  }

  .formAction {
    margin-top: 3rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global {
    select {
      width: 100%;
    }
  }

  &.denseLayout {
    margin-top: 1rem;

    .formGroup {
      margin-bottom: 0.5rem;

      label {
        flex: 0 0 80px;
        max-width: 80px;
      }

      svg {
        margin-right: 5px;
      }
    }

    .formAction {
      margin-top: 1rem;
    }
  }
}
