.wrapper {
  --gap: 13px;
  --sidebar-width-expanded: 382px;
  --sidebar-width-collapsed: 43px;
  --sidebar-width: var(--sidebar-width-expanded);

  display: flex;
  justify-content: space-between;
  gap: var(--gap);

  .right {
    flex: 1;
    max-width: calc(100% - var(--sidebar-width-expanded) - var(--gap));
  }

  .left {
    width: var(--sidebar-width-expanded);
    flex-shrink: 0;

    &.collapsed {
      width: var(--sidebar-width-collapsed);

      & + .right {
        max-width: calc(100% - var(--sidebar-width-collapsed) - var(--gap));
      }
    }
  }
}
