@import 'styles/typography';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bs-primary);
  color: var(--bs-white);
  text-transform: uppercase;
  padding: 12px 25px;
  position: sticky;
  top: -1px;
  z-index: 2;
  border-bottom: 1px solid var(--bs-white);

  .measure {
    font-weight: var(--font-weight-semibold);
  }

  .measureGroupName {
    display: block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
  }

  .container {
    display: flex;
    flex: 1;
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    .measure {
      flex-shrink: 0;
      max-width: 30%;
    }

    .divider {
      border-left: 1px solid var(--bs-white);
      display: block;
      margin: 0 5px;
    }
  }

  .toggleBtn {
    text-transform: uppercase;
    border-radius: 9999px;
    padding: 2px 6px;

    @include body3;
  }
}
