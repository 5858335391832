@import 'styles/typography';

.measureDisplay {
  text-align: center;
  font-weight: var(--font-weight-medium);

  .measureCount {
    line-height: 1.1;

    @include h1;
  }

  .unit {
    text-transform: uppercase;

    @include body3;
  }
}
